















































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import FormValidator from "@/components/mixins/FormValidator.ts";
import VueRecaptcha from "vue-recaptcha";

interface SelectOption {
  value: number | any;
  label: string;
}

@Component({
  components: {
    VueRecaptcha
  }
})
export default class AnularReserva extends Mixins(FormValidator) {
  private anulacionCompleta = false;
  private reserva: any = {};
  private validRut = false;
  private nombreWeb = "";
  private formattedFecha = "";
  private formattedRut = "";
  private reservaUsuario: any = {
    rut: "",
    codigo: "",
    fecha: "",
    id_bloque: ""
  };
  private selectedBloque: any = {
    id: "",
    horario: "",
    cupo: ""
  };

  private selectedFecha: any = null;

  private listaBloques: any = null;
  private disableButton = true;
  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.trim();
    this.reservaUsuario.rut = newValue.replace(/\./g, "");
    newValue = newValue.replace(/\./g, "").replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  @Watch("selectedFecha")
  private edadWatcher(newValue: any) {
    this.reservaUsuario.fecha = newValue;
    this.reservaUsuario.id_bloque = "";
    this.disableButton = true;
    this.getBloques();
  }

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getReservaEvento();
      this.linkCanonical(this.nombreWeb);
    } else {
      this.routerGo("Error");
    }
  }
  private linkCanonical(nombreWeb) {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href =
      "https://muniarica.cl/reserva/" + nombreWeb + "/anular";
    document.head.appendChild(canonicalLink);
  }
  private selectHorario(item: any) {
    if (item.id) {
      this.reservaUsuario.id_bloque = item.id;
      this.disableButton = false;
      this.selectedBloque = item;
    } else {
      this.disableButton = true;
    }
  }
  private selectedBloqueStyle(id) {
    if (this.reservaUsuario.id_bloque == id) {
      return "color:white; background-color: #003da6;";
    }
    return "color: #003da6;";
  }
  private getReservaEvento() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("/reserva/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.reserva = res.data;
        const base = process.env.VUE_APP_BASE_URL;
        if (this.reserva.foto) {
          this.reserva.foto = base + this.reserva.foto;
        } else {
          this.reserva.foto = require("@/assets/banner_desktop.webp");
        }
        if (this.reserva.foto_mobile) {
          this.reserva.foto_mobile = base + this.reserva.foto_mobile;
        } else {
          this.reserva.foto_mobile = require("@/assets/banner_mobile.webp");
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.routerGo("Error");
      });
  }
  private getBloques() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get(
        "/reserva/bloques/anular?id=" +
          this.reserva.id +
          "&fecha=" +
          this.reservaUsuario.fecha
      )
      .then((res: any) => {
        this.listaBloques = res.data;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.listaBloques = [];
        this.$q.loading.hide();
      });
  }
  private anularReserva() {
    if (
      !this.validateRut(this.reservaUsuario.rut) ||
      this.reservaUsuario.rut.trim() == ""
    ) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su Rut",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.codigo.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su código de reserva",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (!this.selectedFecha || this.selectedFecha == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor seleccione una fecha para la reserva",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (!this.reservaUsuario.id_bloque || this.reservaUsuario.id_bloque == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor seleccione un horario",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });

    this.$axios
      .post("/anular/reserva", this.reservaUsuario)
      .then((res: any) => {
        // subir página al inicio
        window.scrollTo(0, 0);
        this.$swal({
          icon: "success",
          title: "Reserva Anulada",
          text: "Su reserva ha sido anulada correctamente.",
          confirmButtonText: "Aceptar"
        }).then(result => {
          // this.$q.loading.show({
          //   delay: 100 // ms
          // });
          // this.$router.go(0);
        });
        this.anulacionCompleta = true;
        this.$q.loading.hide();
      })
      .catch(err => {
        const message = err.response.data.message;
        this.anulacionCompleta = false;
        if (message == "INVALID_DATOS_RUT") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un rut válido.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_DATOS_CODIGO") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un código de reserva válido.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_DATOS_FECHA") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text:
              "Por favor ingrese una fecha de nacimiento válida (DD-MM-AAAA).",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "NO_RESERVA") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "No se ha encontrado una reserva con los datos ingresados.",
            confirmButtonText: "Aceptar"
          });
        } else {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Ha ocurrido un error, por favor intente nuevamente.",
            confirmButtonText: "Aceptar"
          });
        }
        this.$q.loading.hide();
      });
  }

  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      this.validRut = false;
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        this.validRut = false;
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      this.validRut = true;
      return true;
    }
    this.validRut = false;
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
