








import { Component, Vue } from "vue-property-decorator";
import AnularReserva from "@/components/Reserva/AnularReserva.vue";

@Component({
  components: {
    AnularReserva
  }
})
export default class AnularReservaView extends Vue {}
